/**
 * @file Events actions, for creating and deleting
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const createEvent = createAction('CREATE_EVENT')
export const setCreatedEventID = createAction('SET_CREATED_EVENT_ID')

export const updateEvent = createAction('UPDATE_EVENT')
export const setUpdateEventSuccess = createAction('SET_UPDATE_EVENT_SUCCESS')

export const getEvent = createAction('GET_EVENT')
export const setEventsDetails = createAction('SET_EVENTS_DETAILS')
export const setGetEventError = createAction('SET_GET_EVENT_ERROR')
