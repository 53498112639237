/**
 * @file Generic  loading page
 * @author Alwyn Tan
 */

import React from 'react'
import styled, { keyframes } from 'styled-components'
import { COLORS } from '../constants'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const spinningAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div`
  border: 10px solid transparent;
  border-radius: 50%;
  border-top: 10px solid ${COLORS.RonaRed};
  width: 90px;
  height: 90px;
  animation: ${spinningAnimation} 0.5s linear infinite;
`

const Loading = () => {
  return (
    <Container>
      <Loader />
    </Container>
  )
}

export default Loading
