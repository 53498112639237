/**
 * @file Reducers for all authentication
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  setAccessToken,
  setAuthError,
  setAuthLoading,
  setCurrentUser,
} from '../actions/auth'

const defaultState = {
  user: null,
  accessToken: null,
  loading: true, // initial state
  error: '',
}

export default handleActions(
  {
    [`${setAuthLoading}`]: state => ({ ...state, loading: true }),
    [`${setAccessToken}`]: (state, action) => ({
      ...state,
      accessToken: action.payload?.accessToken,
      loading: false,
    }),
    [`${setAuthError}`]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [`${setCurrentUser}`]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
  },
  defaultState
)
