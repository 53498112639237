/**
 * @file Invite user events. For Events, etc
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const setInviteLoading = createAction('INVITE_LOADING')
export const setInviteError = createAction('INVITE_ERROR')
export const setInviteSuccess = createAction('INVITE_SUCCESS')
export const resetInviteState = createAction('RESET_INVITE_STATE')

export const inviteToEvent = createAction('INVITE_TO_EVENT')
