/**
 * @file Events!
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import EventNav from '../../components/events/eventNav'

const FullScreenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  padding-top: 80px;

  > form {
    width: 100%;
    max-width: 315px;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 15px;
    }
  }
`

const Events = () => {
  return (
    <FullScreenContainer>
      <Container>
        <EventNav />
        <Outlet />
      </Container>
    </FullScreenContainer>
  )
}

Events.propTypes = {}

export default Events
