/**
 * @file
 * @author Alwyn Tan
 */

import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'

import debounce from 'lodash.debounce'
import { COLORS, USER_FIND_URL } from '../constants'
import TextArea from './textarea'

const reactSelectStyles = {
  container: provided => ({
    ...provided,
    outline: 'none',
    border: 'none',
    width: 315,
    maxWidth: '100%',
  }),
  control: provided => ({
    ...provided,
    borderRadius: 10,
    border: `2px solid ${COLORS.EarlGrey}`,
    boxShadow: 'none',

    ':hover': {
      border: `2px solid ${COLORS.EarlGrey}`,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: 15,
    fontSize: '0.933em',
    fontFamily: 'inherit',
    fontWeight: 700,
  }),
  placeholder: provided => ({
    ...provided,
    opacity: 0.6,
    fontWeight: 500,
  }),
  input: provided => ({
    ...provided,
    '& input': {
      fontWeight: 700,
    },
  }),
}

const UserAutofillInput = ({ placeholder, onChange, value }) => {
  const accessToken = useSelector(state => state.auth.accessToken)

  const debouncedDispatch = useCallback(
    debounce(async (value, callback) => {
      if (accessToken) {
        const response = await fetch(USER_FIND_URL, {
          method: 'POST',
          body: JSON.stringify({ query: value }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const respJSON = await response.json()

        const options = respJSON.users.map(user => ({
          value: user.id,
          label: user.name,
          isFixed: true,
          host_info: user.host_info,
        }))

        callback(options)
      }
    }, 500),
    []
  )

  const loadOptions = (inputValue, callback) => {
    debouncedDispatch(inputValue, callback)
  }

  return (
    <>
      <AsyncSelect
        styles={reactSelectStyles}
        cacheOptions
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder={placeholder}
        value={value} // need to load in options if ????
      />
      {value && (
        <TextArea
          style={
            !value.host_info ? { fontStyle: 'italic', fontWeight: 400 } : {}
          }
          value={value.host_info || 'Host info not set up :('}
          disabled
        />
      )}
    </>
  )
}

export default UserAutofillInput
