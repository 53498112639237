/**
 * @file Event creation and deletion logic
 * @author Alwyn Tan
 */

import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { INVITE_EVENT_URL } from '../../constants'
import {
  inviteToEvent,
  setInviteError,
  setInviteLoading,
  setInviteSuccess,
} from '../actions/invite'
import { post } from '../saga-fetch'

function* startInviteToEvent(action) {
  yield put(setInviteLoading(true))
  const { phoneNumbers, eventID } = action.payload
  const { success, error } = yield post(INVITE_EVENT_URL, {
    phoneNumbers,
    eventID,
  })

  if (success) {
    yield put(setInviteLoading(false))
    yield put(setInviteSuccess(true))
  } else {
    console.error(error)
    yield put(
      setInviteError(
        typeof error === 'string' ? error : 'Error: Unable to invite'
      )
    )
  }
}

function* watchInviteToEvent() {
  yield takeLatest(`${inviteToEvent}`, startInviteToEvent)
}

export default function* eventSaga() {
  yield all([fork(watchInviteToEvent)])
}
