/**
 * @file Reducers for all authentication
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  resetInviteState,
  setInviteError,
  setInviteLoading,
  setInviteSuccess,
} from '../actions/invite'

const defaultState = {
  loading: false,
  success: false,
  error: '',
}

export default handleActions(
  {
    [`${resetInviteState}`]: () => ({
      ...defaultState,
    }),
    [`${setInviteLoading}`]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [`${setInviteSuccess}`]: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    [`${setInviteError}`]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
  defaultState
)
