/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;

  > h4 {
    flex: 1;
  }

  > span {
    font-size: 20px;
    cursor: pointer;
  }
`

const InvitedPhoneRow = ({ phone, onDelete }) => {
  return (
    <Container>
      <h4>{phone}</h4>
      <span className="icon-x-circle" onClick={onDelete}></span>
    </Container>
  )
}

InvitedPhoneRow.propTypes = {
  phone: PropTypes.string,
  onDelete: PropTypes.func,
}

export default InvitedPhoneRow
