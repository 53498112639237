/**
 * @file Component to invite users to the event
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { findPhoneNumbersInText } from 'libphonenumber-js/min'
import { SecondaryInput } from '../../components/input'
import InvitedPhoneRow from '../../components/invite/invitedPhoneRow'
import Button from '../../components/button'
import { useDispatch, useSelector } from 'react-redux'
import { inviteToEvent, resetInviteState } from '../../redux/actions/invite'

const Container = styled.div`
  width: 100%;
`

const InviteToEvent = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [phoneNumbers, setPhoneNumbers] = useState('')
  const [phoneNumberSet, setPhoneNumberSet] = useState(new Set())

  const inviteLoading = useSelector(state => state.invite.loading)
  const inviteSuccess = useSelector(state => state.invite.success)
  const inviteError = useSelector(state => state.invite.error)

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      console.log(phoneNumbers)
      const numbers = findPhoneNumbersInText(
        phoneNumbers.replaceAll(' ', ','),
        'US'
      )
      const filtered = numbers.map(curr => curr.number.number)
      setPhoneNumbers('')
      setPhoneNumberSet(new Set([...phoneNumberSet, ...filtered]))
    }
  }

  const handleSendInvites = () => {
    dispatch(inviteToEvent({ phoneNumbers: [...phoneNumberSet], eventID: id }))
  }

  useEffect(() => {
    return () => {
      dispatch(resetInviteState())
    }
  }, [dispatch])

  return (
    <Container>
      <h3 style={{ marginBottom: 10 }}>Invite Users to Event</h3>
      <p>EventID: {id}</p>
      {/* <p style={{ marginBottom: 10 }}>Title: "placeholder"</p> */}
      <SecondaryInput
        value={phoneNumbers}
        onChange={e => setPhoneNumbers(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Add Guests (Phone Numbers)"
        style={{ width: '100%', marginBottom: 20 }}
      />
      {[...phoneNumberSet].map(curr => (
        <InvitedPhoneRow
          key={curr}
          phone={curr}
          onDelete={() =>
            setPhoneNumberSet(
              new Set([...phoneNumberSet].filter(elem => elem !== curr))
            )
          }
        />
      ))}
      <Button
        style={{ marginTop: phoneNumberSet.size > 0 && 10, width: '100%' }}
        onClick={handleSendInvites}
        disabled={phoneNumberSet.size === 0 || inviteLoading}
      >
        Send Invites
      </Button>
      {inviteSuccess && (
        <p style={{ color: 'green' }}>Successfully invited users</p>
      )}
      {inviteError && <p style={{ color: 'red' }}>{inviteError}</p>}
    </Container>
  )
}

export default InviteToEvent
