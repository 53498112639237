/**
 * @file Home page for admin
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const Home = () => {
  return <Container></Container>
}

export default Home
