/**
 * @file Create an event
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { SecondaryInput } from '../../components/input'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { setGetEventError } from '../../redux/actions/events'

const Error = styled.p`
  color: red;
  text-align: center;
`

const EditEvent = () => {
  const [eventID, setEventID] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const eventError = useSelector(state => state.events.getEventError)

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(setGetEventError(''))
    navigate(`/events/edit/${eventID}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <SecondaryInput
        name="eventID"
        placeholder="Enter Event ID"
        value={eventID}
        onChange={e => setEventID(e.target.value)}
      />
      {eventError && <Error>{eventError}</Error>}
      <Button disabled={!eventID}>Find Event</Button>
    </form>
  )
}

export default EditEvent
