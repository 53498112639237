/**
 * @file Create an event
 * @author Alwyn Tan
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createEvent } from '../../redux/actions/events'
import Loading from '../loading'
import EditEventFields from '../../components/events/editEventFields'
import 'react-datepicker/dist/react-datepicker.css'
import { CLIENT_HOSTNAME } from '../../constants'

const CreateEvent = () => {
  const dispatch = useDispatch()
  const createdEventID = useSelector(state => state.events.createdEventID)
  const createEventLoading = useSelector(
    state => state.events.createEventLoading
  )

  if (createEventLoading) return <Loading />

  if (createdEventID) {
    const link = `${CLIENT_HOSTNAME}/app/events/${createdEventID}`
    return (
      <form>
        <h2 style={{ marginBottom: 20 }}>Success! Event Created!</h2>
        <a href={link}>{link}</a>
      </form>
    )
  }

  const handleSubmit = values => {
    dispatch(createEvent(values))
  }

  return <EditEventFields onSubmit={handleSubmit} />
}

export default CreateEvent
