/**
 * @file Input component
 * @author Alwyn Tan
 */

import styled from 'styled-components'
import { COLORS } from '../constants'
import TextareaAutosize from 'react-textarea-autosize'

const TextArea = styled(TextareaAutosize)`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 700;
  font-size: 0.933em;
  background-color: transparent;
  min-height: 40px;
  padding: 10px 0 7px 15px;
  border: 2px solid ${COLORS.EarlGrey};
  max-width: 100%;
  resize: none;
  appearance: none;

  ::placeholder {
    opacity: 0.6;
    font-weight: 500;
  }

  :disabled {
    background-color: ${COLORS.EarlGrey};
  }
`

export default TextArea
