/**
 * @file Saga root manager
 * @author Alwyn Tan
 */

import { all, fork } from 'redux-saga/effects'
import authSaga from './auth'
import eventsSaga from './events'
import inviteSaga from './invite'

export default function* rootSaga() {
  yield all([fork(authSaga), fork(eventsSaga), fork(inviteSaga)])
}
