/**
 * @file Menu side bar
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Route, Link } from 'react-router-dom'

import logo from '../images/logo.png'
import { COLORS } from '../constants'

const Container = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  padding-left: 45px;

  > img {
    width: 90px;
  }
`

const Nav = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
`

const NavItem = styled(Link)`
  > span {
    font-size: 22px;
    padding-right: 10px;
  }

  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  outline: none;
  text-decoration: none;
  color: black;
`

const Indicator = styled.div`
  height: 40px;
  width: 5px;
  border-radius: 0px 10px 10px 0px;
  background-color: ${COLORS.RonaRed};
  position: absolute;
  left: 0;
`

const Menu = props => {
  return (
    <Container>
      <img src={logo} alt="logo" />
      <Nav>
        <NavItem to="/events/create">
          <Route path="/events" component={Indicator} />
          <span className="icon-calendar" />
          <h3>Events</h3>
        </NavItem>
      </Nav>
    </Container>
  )
}

export default Menu
