/**
 * @file Image input + preview
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLORS } from '../constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 315px;
  max-width: 100%;
  height: 215px;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: ${`2px solid ${COLORS.EarlGrey}`};
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`

const ImageInput = ({ onChange, value }) => {
  const [eventPic, setEventPicPreview] = useState('')

  useEffect(() => {
    let objectURL
    if (typeof value === 'object') {
      objectURL = URL.createObjectURL(value)
      setEventPicPreview(objectURL)
    } else {
      setEventPicPreview(value)
    }

    return () => URL.revokeObjectURL(objectURL)
  }, [value])

  const handleImageUpload = e => {
    if (e.target.files.length > 0) {
      // dont allow if size > 2mb, server will validate too
      if (e.target.files[0].size > 16 * 1024 * 1024) {
        // if > 2mb
        alert('Please upload a photo less than 16mb')
        e.target.value = ''
        return
      }

      // set the preview here
      var reader = new FileReader()

      reader.readAsDataURL(e.target.files[0])
      if (onChange) onChange(e.target.files[0])
    }
  }

  return (
    <Container>
      <ImageContainer>
        {eventPic ? (
          <img src={eventPic} alt="pic" />
        ) : (
          <div
            className="icon-camera"
            style={{
              color: COLORS.EarlGrey,
              fontSize: 50,
            }}
          ></div>
        )}
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      </ImageContainer>
    </Container>
  )
}

ImageInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ImageInput
