/**
 * @file App container with routes
 * @author Alwyn Tan
 */

import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Login from './pages/login'
import Home from './pages/home'
import Menu from './components/menu'
import CreateEvent from './pages/events/createEvent'
import EditEvent from './pages/events/editEvent'
import FourOhFour from './pages/404'
import Loading from './pages/loading'
import EditEventDetails from './pages/events/editEventDetails'
import Events from './pages/events'
import InviteToEvent from './pages/invite/inviteToEvent'
import Invite from './pages/invite'

const Container = styled.div`
  min-height: 100%;
  display: flex;
`

const App = () => {
  const isLoggedIn = useSelector(state => Boolean(state.auth.accessToken))
  const isLoading = useSelector(state => state.auth.loading)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <Router>
        {isLoggedIn ? (
          <>
            <Menu />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/events" element={<Events />}>
                <Route path="/create" element={<CreateEvent />} />
                <Route path="/edit" element={<EditEvent />} />
                <Route path="/edit/:id" element={<EditEventDetails />} />
              </Route>
              <Route path="/invite" element={<Invite />}>
                <Route path="/event/:id" element={<InviteToEvent />} />
              </Route>
              <Route path="/auth" element={<Navigate to="/" />} />
              <Route path="/*" element={<FourOhFour />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/auth" element={<Login />} />
            <Route path="/*" element={<Navigate to="/auth" />} />
          </Routes>
        )}
      </Router>
    </Container>
  )
}

export default App
