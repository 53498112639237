/**
 * @file Auth actions
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const silentLogin = createAction('SILENT_LOGIN')
export const login = createAction('LOGIN')
export const setAccessToken = createAction('SET_ACCESS_TOKEN')
export const setAuthError = createAction('SET_AUTH_ERROR')
export const setCurrentUser = createAction('SET_CURRENT_USER')

export const setAuthLoading = createAction('AUTH_LOADING')
