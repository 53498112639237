/**
 * @file Not Found page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const FourOhFour = () => {
  return <Container>Page Not Found :(</Container>
}

export default FourOhFour
