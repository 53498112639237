/**
 * @file Nav component for past and upcoming
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

const Links = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  padding-bottom: 5px;
  z-index: 1;
  margin-bottom: 15px;
  background-color: white;
`

const StyledLink = styled(({ active, ...props }) => <Link {...props} />)`
  color: black;
  text-decoration: none;
  margin-right: 25px;
  opacity: ${props => (props.active ? 1 : 0.5)};

  > div {
    display: ${props => (props.active ? 'block' : 'none')};
  }
`

const Line = styled.div`
  width: 75%;
  height: 2px;
  background-color: black;
  border-radius: 2px;
  margin-top: 2px;
`

const EventNav = () => {
  const location = useLocation()

  return (
    <Links>
      <StyledLink
        to="/events/edit"
        active={location.pathname.startsWith('/events/edit')}
      >
        <h3>Edit an Event</h3>
        <Line />
      </StyledLink>
      <StyledLink
        to="/events/create"
        active={location.pathname === '/events/create'}
      >
        <h3>Create an Event</h3>
        <Line />
      </StyledLink>
    </Links>
  )
}

export default EventNav
