// colors
export const COLORS = {
  EarlGrey: '#F2F1F6',
  RonaRed: '#B60C0C',
  MidnightBlue: '#121D21',
  Charcoal: '#15181A',
  FrozenGray: '#292D2F',
}

export const BASE_API_URL = '/api'

let client_hostname
if (process.env.REACT_APP_ENV === 'development')
  client_hostname = 'http://localhost:8000'
else if (process.env.REACT_APP_ENV === 'staging')
  client_hostname = 'https://staging.soloclub.app'
else if (process.env.REACT_APP_ENV === 'production')
  client_hostname = 'https://soloclub.app'
export const CLIENT_HOSTNAME = client_hostname

// auth routes
export const REFRESH_TOKEN_URL = `${BASE_API_URL}/v2/auth/refresh-token`
export const LOGIN_ACCOUNT_URL = `${BASE_API_URL}/v2/auth/login`

// user routes
export const USER_ME_URL = `${BASE_API_URL}/user/me`
export const USER_FIND_URL = `${BASE_API_URL}/user/find`

// event routes
export const CREATE_EVENT_URL = `${BASE_API_URL}/event/create`
export const UPDATE_EVENT_URL = `${BASE_API_URL}/event/update`
export const GET_EVENT_URL = `${BASE_API_URL}/event/get`

// invite routes
export const INVITE_EVENT_URL = `${BASE_API_URL}/event/invite`
