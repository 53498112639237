/**
 * @file Event creation and deletion logic
 * @author Alwyn Tan
 */

import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  CREATE_EVENT_URL,
  GET_EVENT_URL,
  UPDATE_EVENT_URL,
} from '../../constants'
import {
  createEvent,
  getEvent,
  setCreatedEventID,
  setEventsDetails,
  setGetEventError,
  setUpdateEventSuccess,
  updateEvent,
} from '../actions/events'
import { get } from '../saga-fetch'

function* startCreateEvent(action) {
  const newEventData = action.payload
  const accessToken = yield select(state => state.auth.accessToken)

  const formData = new FormData()
  for (const pair of Object.entries(newEventData))
    formData.append(pair[0], pair[1])

  // body is multipart form data
  try {
    const { eventID } = yield fetch(CREATE_EVENT_URL, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(response => response.json())

    yield put(setCreatedEventID(eventID))
  } catch (err) {
    // TODO: handle erorr creation event
    console.error(err)
  }
}

function* startGetEvent(action) {
  yield put(setGetEventError(''))
  const eventID = action.payload

  try {
    if (!eventID) throw Error('no eventID')
    const event = yield get(`${GET_EVENT_URL}/${eventID}`)
    const currentEvents = yield select(state => state.events.details)
    const newEventsDetails = { ...currentEvents, [eventID]: event }
    yield put(setEventsDetails(newEventsDetails))
  } catch (err) {
    yield put(setGetEventError("Can't find that event. Try again?"))
    console.error(err)
  }
}

function* startUpdateEvent(action) {
  const updatedEvent = action.payload
  const accessToken = yield select(state => state.auth.accessToken)

  const formData = new FormData()
  for (const pair of Object.entries(updatedEvent))
    formData.append(pair[0], pair[1])

  try {
    const event = yield fetch(UPDATE_EVENT_URL, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(response => response.json())

    yield put(setUpdateEventSuccess(true))
    const currentEvents = yield select(state => state.events.details)
    const newEventsDetails = { ...currentEvents, [event.id]: event }
    yield put(setEventsDetails(newEventsDetails))
  } catch (err) {
    yield put(setUpdateEventSuccess(false))
    console.error(err)
  }
}

function* watchCreateEvent() {
  yield takeLatest(`${createEvent}`, startCreateEvent)
}

function* watchGetEvent() {
  yield takeLatest(`${getEvent}`, startGetEvent)
}

function* watchUpdateEvent() {
  yield takeLatest(`${updateEvent}`, startUpdateEvent)
}

export default function* eventSaga() {
  yield all([
    fork(watchCreateEvent),
    fork(watchGetEvent),
    fork(watchUpdateEvent),
  ])
}
