/**
 * @file Authentication related sagas
 * @author Alwyn Tan
 */

import isMobilePhone from 'validator/lib/isMobilePhone'
import {
  silentLogin,
  setAuthLoading,
  setAccessToken,
  setCurrentUser,
  login,
  setAuthError,
} from '../actions/auth'
import {
  LOGIN_ACCOUNT_URL,
  REFRESH_TOKEN_URL,
  USER_ME_URL,
} from '../../constants'
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

function* startJWTExpiryTimeout(expiry) {
  if (expiry) {
    const channel = eventChannel(emit => {
      setTimeout(() => {
        emit(silentLogin())
      }, expiry)

      return () => {}
    })

    const channelAction = yield take(channel)
    yield put(channelAction)
    channel.close()
  }
}

function* fetchCurrentUser(accessToken) {
  try {
    const user = yield fetch(USER_ME_URL, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(response => response.json())

    yield put(setCurrentUser(user))
  } catch {
    yield put(setCurrentUser(null))
  }
}

function* postAuth(accessToken, expiry) {
  yield call(fetchCurrentUser, accessToken)
  yield put(setAccessToken({ accessToken }))
  yield put(setAuthError(''))
  yield fork(startJWTExpiryTimeout, expiry)
}

function* startSilentLogin() {
  yield put(setAuthLoading())
  try {
    const { accessToken, expiry } = yield fetch(REFRESH_TOKEN_URL, {
      credentials: 'include',
    }).then(response => response.json())
    yield fork(postAuth, accessToken, expiry)
  } catch (err) {
    console.error(`token error: ${err}`)
    yield put(setAccessToken(null))
    yield put(setAuthError('Please login again'))
  }
}

function* startLogin(action) {
  yield put(setAuthLoading())

  const { phoneNumber, password } = action.payload

  if (!isMobilePhone(phoneNumber) || password.length === 0) {
    yield put(setAccessToken(null))
    yield put(setAuthError('Please enter a valid phoneNumber and password'))
    return
  }

  try {
    const { accessToken, expiry } = yield fetch(LOGIN_ACCOUNT_URL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ phoneNumber, password }),
      headers: { 'Content-Type': 'application/json' },
    }).then(response => response.json())
    yield fork(postAuth, accessToken, expiry)
  } catch (err) {
    console.error(`token error: ${err}`)
    yield put(setAccessToken(null))
    yield put(setAuthError('Login Failed. Please try again.'))
  }
}

function* watchSilentLogin() {
  yield takeLatest(`${silentLogin}`, startSilentLogin)
}

function* watchLogin() {
  yield takeLatest(`${login}`, startLogin)
}

export default function* authSaga() {
  yield all([fork(watchSilentLogin), fork(watchLogin)])
}
