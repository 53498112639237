/**
 * @file Reducers for all authentication
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  createEvent,
  setCreatedEventID,
  setEventsDetails,
  setGetEventError,
  setUpdateEventSuccess,
  updateEvent,
} from '../actions/events'

const defaultState = {
  createdEventID: null,
  createEventLoading: false,
  updateEventLoading: false,
  updateEventSuccess: false,
  details: {},
  getEventError: '',
}

export default handleActions(
  {
    [`${createEvent}`]: state => ({
      ...state,
      createdEventID: null,
      createEventLoading: true,
    }),
    [`${setCreatedEventID}`]: (state, action) => ({
      ...state,
      createdEventID: action.payload,
      createEventLoading: false,
    }),
    [`${updateEvent}`]: state => ({
      ...state,
      updateEventLoading: true,
      updateEventSuccess: false,
    }),
    [`${setUpdateEventSuccess}`]: (state, action) => ({
      ...state,
      updateEventLoading: false,
      updateEventSuccess: action.payload,
    }),
    [`${setEventsDetails}`]: (state, action) => ({
      ...state,
      details: action.payload,
      getEventError: '',
    }),
    [`${setGetEventError}`]: (state, action) => ({
      ...state,
      getEventError: action.payload,
    }),
  },
  defaultState
)
