/**
 * @file Login component
 * @author Alwyn Tan
 */

import React, { useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import logo from '../images/logo.png'
import Input from '../components/input'
import Button from '../components/button'
import { login } from '../redux/actions/auth'
import { COLORS } from '../constants'
import Loading from './loading'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-bottom: 50px;
  width: 100%;

  > img {
    width: 80px;
    margin-bottom: 30px;
  }

  > * {
    margin-bottom: 10px;
  }
`

const Error = styled.h4`
  color: ${COLORS.RonaRed};
`

const Login = () => {
  const dispatch = useDispatch()
  const phoneNumberRef = useRef(null)
  const passwordRef = useRef(null)

  const error = useSelector(state => state.auth.error)
  const loading = useSelector(state => state.auth.loading)

  const handleSignIn = e => {
    e.preventDefault()
    dispatch(
      login({
        phoneNumber: phoneNumberRef.current.value,
        password: passwordRef.current.value,
      })
    )
  }

  if (loading) return <Loading />

  return (
    <Container onSubmit={handleSignIn}>
      <img src={logo} alt="logo" />
      <Input
        ref={phoneNumberRef}
        placeholder="Phone Number"
        type="tel"
        required
      />
      <Input
        ref={passwordRef}
        placeholder="Password"
        type="password"
        required
      />
      <Button>Sign In</Button>
      {error && <Error>{error}</Error>}
    </Container>
  )
}

export default Login
