/**
 * @file Button component
 * @author Alwyn Tan
 */

import styled from 'styled-components'
import { COLORS } from '../constants'

const Button = styled.button`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 700;
  font-size: 1.077em;
  background-color: ${COLORS.RonaRed};
  height: 40px;
  padding: 0 15px;
  max-width: 100%;
  color: white;
  border: none;
  cursor: pointer;
  transition: opacity 0.5s ease;

  :hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.5;
  }
`

export default Button
