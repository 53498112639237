/**
 * @file Create an event
 * @author Alwyn Tan
 */

// TODO: down the line, refactor this to only send updated infos, not the whole chunk

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SecondaryInput } from '../input'
import Button from '../button'
import DatePicker from 'react-datepicker'
import UserAutofillInput from '../userAutofillInput'
import ImageInput from '../imageInput'

import { useForm, Controller } from 'react-hook-form'

import 'react-datepicker/dist/react-datepicker.css'
import TextArea from '../textarea'

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  > *:first-child {
    margin-right: 10px;
  }
`

const makeHostFields = host => ({
  host_info: host.host_info,
  isFixed: true,
  label: host.name,
  value: host.id,
})

const mapExistingDetails = existingDetails => ({
  picture: existingDetails?.picture || '',
  title: existingDetails?.title || '',
  about: existingDetails?.about || '',
  startDate: existingDetails?.startDate
    ? new Date(existingDetails?.startDate)
    : '',
  endDate: existingDetails?.endDate ? new Date(existingDetails?.endDate) : '',
  location:
    existingDetails?.location !== 'online' ? existingDetails?.location : '',
  link: existingDetails?.link || '',
  online: existingDetails?.location === 'online',
  maxParticipants: existingDetails?.maxParticipants || '',
  host: existingDetails?.host ? makeHostFields(existingDetails?.host) : '',
  additionalInfoQuestion: existingDetails?.additionalInfoQuestion || '',
  hostShouldReviewMatches: existingDetails?.hostShouldReviewMatches || false,
})

const EditEventFields = ({ onSubmit, existingDetails }) => {
  const { register, handleSubmit, control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: mapExistingDetails(existingDetails),
  })
  const isOnlineEvent = watch('online')

  const parseAndSubmitData = data => {
    const parsed = { ...data }
    if (data.online) parsed.location = 'online'
    delete parsed.online
    parsed.host = data.host.value

    onSubmit(parsed)
  }

  useEffect(() => {
    reset(mapExistingDetails(existingDetails))
  }, [reset, existingDetails])

  return (
    <form onSubmit={handleSubmit(parseAndSubmitData)}>
      <Controller
        control={control}
        name="picture"
        as={ImageInput}
        rules={{ required: true }}
      />
      <SecondaryInput
        name="title"
        placeholder="Event Title"
        ref={register({ required: true })}
      />
      <TextArea
        name="about"
        placeholder="Event Description"
        ref={register({ required: true })}
      />
      <Controller
        control={control}
        name="startDate"
        rules={{ required: true }}
        render={props => (
          <DatePicker
            {...props}
            placeholderText="Start Date & Time"
            selected={props.value}
            customInput={<SecondaryInput />}
            showTimeSelect
            dateFormat="Pp"
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        rules={{ required: true }}
        render={props => (
          <DatePicker
            {...props}
            placeholderText="End Date & Time"
            selected={props.value}
            customInput={<SecondaryInput />}
            showTimeSelect
            dateFormat="Pp"
          />
        )}
      />
      <Row>
        {isOnlineEvent === true ? (
          <SecondaryInput
            key="link"
            name="link"
            placeholder="Event Link"
            ref={register({ required: true })}
          />
        ) : (
          <SecondaryInput
            key="location"
            name="location"
            placeholder="Event Location"
            ref={register({ required: true })}
          />
        )}
        <input
          type="checkbox"
          id="online"
          placeholder="online"
          name="online"
          ref={register}
        />
        <label htmlFor="online" style={{ paddingLeft: 5 }}>
          <h4>online</h4>
        </label>
      </Row>
      <SecondaryInput
        placeholder="Max Participants"
        type="number"
        min={2}
        name="maxParticipants"
        ref={register({ required: true })}
      />
      <Controller
        name="host"
        as={UserAutofillInput}
        control={control}
        rules={{ required: true }}
      />
      <SecondaryInput
        name="additionalInfoQuestion"
        placeholder="Additional Question (Optional)"
        ref={register()}
      />
      <Row style={{ justifyContent: 'flex-start' }}>
        <input
          type="checkbox"
          id="hostShouldReviewMatches"
          name="hostShouldReviewMatches"
          ref={register}
        />
        <label htmlFor="online" style={{ paddingLeft: 5 }}>
          <h4>Prompt Host to Review Matches</h4>
        </label>
      </Row>
      <Button disabled={!formState.isValid || !formState.isDirty}>
        Save Event
      </Button>
    </form>
  )
}

export default EditEventFields
