/**
 * @file Input component
 * @author Alwyn Tan
 */

import styled from 'styled-components'
import { COLORS } from '../constants'

const Input = styled.input`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 700;
  font-size: 0.933em;
  background-color: ${COLORS.EarlGrey};
  height: 40px;
  padding-left: 15px;
  border-style: solid;
  border-color: #d63031;
  border-width: ${props => (props.error ? 2 : 0)};
  max-width: 100%;
  appearance: none;

  ::placeholder {
    opacity: 0.6;
    font-weight: 500;
  }
`

export const SecondaryInput = styled.input`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 700;
  font-size: 0.933em;
  background-color: transparent;
  height: 40px;
  padding-left: 15px;
  border: 2px solid ${COLORS.EarlGrey};
  max-width: 100%;
  appearance: none;

  ::placeholder {
    opacity: 0.6;
    font-weight: 500;
  }
`

export default Input
