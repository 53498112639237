/**
 * @file The editing page for the event details
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getEvent, updateEvent } from '../../redux/actions/events'
import Loading from '../loading'
import EditEventFields from '../../components/events/editEventFields'
import { CLIENT_HOSTNAME } from '../../constants'

const EditEventDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const updateEventLoading = useSelector(
    state => state.events.updateEventLoading
  )
  const updateEventSuccess = useSelector(
    state => state.events.updateEventSuccess
  )
  const eventError = useSelector(state => state.events.getEventError)
  const eventDetails = useSelector(state => state.events.details[id])

  useEffect(() => {
    if (eventError) {
      // undefined = loading, null means nothing exist for this ev
      navigate(`/events/edit`)
    }
  }, [eventError, navigate])

  useEffect(() => {
    // dispatch for the event
    if (id) dispatch(getEvent(id))
  }, [id, dispatch])

  if (!eventDetails) {
    return <Loading />
  }

  const handleSubmit = data => {
    dispatch(updateEvent({ ...data, id }))
  }

  if (updateEventLoading) return <Loading />

  const link = `${CLIENT_HOSTNAME}/app/e/${id}`
  return (
    <>
      {updateEventSuccess && (
        <h3 style={{ color: '#27ae60', paddingBottom: 20 }}>
          Event updated successfully!
        </h3>
      )}
      <h4>Editing Event: {id}</h4>
      <a style={{ paddingBottom: 20, display: 'block' }} href={link}>
        {link}
      </a>
      <EditEventFields existingDetails={eventDetails} onSubmit={handleSubmit} />
    </>
  )
}

EditEventDetails.propTypes = {}

export default EditEventDetails
