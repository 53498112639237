/**
 * @file Helpers for fetching in sagas
 * @author Alwyn Tan
 */

import { select } from 'redux-saga/effects'

/**
 * Makes a post request to the specified url
 * @param {String} url url to post
 * @param {Object} body json body object
 */
export function* post(url, body) {
  const headers = { 'Content-Type': 'application/json' }

  const accessToken = yield select(state => state.auth.accessToken)
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  return yield fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  }).then(response => response.json())
}

/**
 * Makes a get request to the specified url
 * @param {String} url url to get
 */
export function* get(url) {
  const headers = {}

  const accessToken = yield select(state => state.auth.accessToken)
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`

  return yield fetch(url, { headers }).then(response => response.json())
}
