/**
 * @file Invites parent component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

const FullScreenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  padding-top: 80px;
  width: 100%;
  max-width: 400px;

  > div {
    display: flex;
    flex-direction: column;
  }
`

const Invite = () => {
  return (
    <FullScreenContainer>
      <Container>
        <Outlet />
      </Container>
    </FullScreenContainer>
  )
}

Invite.propTypes = {}

export default Invite
