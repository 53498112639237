/**
 * @file Manager for all the reducers of the app
 * @author Alwyn Tan
 */

import { combineReducers } from 'redux'

import auth from './auth'
import events from './events'
import invite from './invite'

export default combineReducers({ auth, events, invite })
